import { Params } from '@angular/router';

import { WgDropdownItem } from '@watchguard/wg-dropdown';

export interface TranslationParam {
    [key: string]: string | undefined;
}

export enum IndustryList {
    Aerospace_Defense = 'Aerospace/Defense',
    BusinessServices = 'Business Services',
    ComputerSystemsDevelopment = 'Computer Systems Development',
    DataProcessing = 'Data Processing',
    E_Commerce = 'E-Commerce',
    Education = 'Education',
    Energy_Utilities_Mining = 'Energy/Utilities/Mining',
    Engineering_Architecture_Construction = 'Engineering/Architecture/Construction',
    Entertainment_Publishing = 'Entertainment/Publishing',
    Finance = 'Finance',
    Government = 'Government',
    Healthcare = 'Healthcare',
    Hotel_Restaurant_Tourism = 'Hotel/Restaurant/Tourism',
    Insurance_RealEstate_Legal = 'Insurance/Real Estate/Legal',
    ISP = 'ISP',
    Manufacturing = 'Manufacturing',
    Marketing = 'Marketing',
    NetworkServices = 'Network Services',
    Non_Profit = 'Non-Profit',
    OtherComputerRelated = 'Other Computer Related',
    OtherNon_ComputerRelated = 'Other Non-Computer Related',
    Pharmaceutical = 'Pharmaceutical',
    Retail = 'Retail',
    SystemsSecurity = 'Systems Security',
    Telecommunications = 'Telecommunications',
    Transportation = 'Transportation'
}

export interface ResetPassword {
    token: string;
    username: string;
    password: string;
    activateUser: boolean;
    optedInFromEmail: boolean;
};

export interface CreateAccountData {
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    phone: string;
    company: string;
    industry: string;
    street: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    optedInForEmail: boolean;
};

export interface RequestResetPassword{
    userName: string;
};

export interface ResetPassword{
    password: string;
}

export interface SetPassword{
    password: string;
    regionId: number;
    token: string;
}

export interface RetrieveUserName{
    email: string;
}

export interface ApiResponse {
    status: string;
    message?: string;
    errors?: Error[];
}

export interface Error {
    code: number;
    component?: string,
    message: string;
}

export interface UserExistsApiResponse {
    status: string;
    user_exists: boolean;
}

export interface ValidateTokenResponse{
    status: string;
    is_token_valid: boolean;
    user_name: string;
};

export interface UrlData {
    isValid?: boolean;
    params: Params;
}

export interface ValidatePasswordResponse {
    status: string;
    password_compromised: boolean;
};

export interface CountryList {
    [key: string]: {
        zone: string;
        states: WgDropdownItem[];
    };
}

export interface ErrorContent {
    errorHeader: string;
    errorMessage: string;
    redirectMessage: string;
}

export interface EnvUrls {
    dev: string;
    qa: string;
    staging: string;
    prod: string;
}

export interface GetAccountInfoByContactIdResponse {
    status: string;
    account_exists: boolean;
    account_details: AccountDetails;
};

export interface AccountDetails {
    regionId: string;
    isEmailDuplicateInSameAccount: boolean;
    contact: ContactDetails;
}

export interface ContactDetails {
    email: string;
    accountId__c: string;
}

export interface InviteFromSalesforce {
    firstName: string;
    lastName: string;
    email: string;
    userName: string;
    phone: string;
    companyName?: string;
    industry?: string;
    street?: string;
    postalCode?: string;
    city?: string;
    state?: string;
    country?: string;
    optedInForEmail?: boolean;
    password: string;
    accountId: string;
    regionId: string;
    nativeSalesforceContactId: string;
    accountExists: string;
};

export interface ReqParam {
    [key: string]: string;
}

export interface Config {
    auth: AuthConfig;
    accountManagerBaseUrl: string;
    scope: string;
}

export interface AuthConfig {
    clientId: string;
    authority: string;
    knownAuthorities: Array<string>;
    postLogoutUrl: string;
}

export interface EnvConfig {
    dev: Config;
    qa: Config;
    staging: Config;
    prod: Config;
}

export interface ValidateEmailTokenResponse {
    isTokenValid: boolean;
    pendingEmailId: string;
    oldEmailId: string;
    status: string;
    message: string;
}

export interface ConfirmPendingEmail {
    token: string;
    federation_id: string;
    email: string;
    account_id: string;
    region_id: number;
    username: string;
}

export interface CancelPendingEmail {
    federation_id: string;
    account_id: string;
    region_id: number;
    username: string;
}

export interface GetPendingEmailResponse {
    isEmailChangePending: boolean;
    pendingEmail: string;
}
