import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE
} from '@azure/msal-angular';

import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication
} from '@azure/msal-browser';

import { WgHideShowPasswordModule } from '@watchguard/wg-hide-show-password';
import { WgFormsModule } from '@watchguard/wg-forms';
import { WgDropdownModule } from '@watchguard/wg-dropdown';
import { WgButtonModule } from '@watchguard/wg-button';
import { LoaderModule, LoaderService } from '@watchguard/wg-loader';
import { WgNotificationsModule, WgNotificationsService } from '@watchguard/wg-notifications';
import { WgDialogModule } from '@watchguard/wg-dialog';
import { LocaleResolverService } from '@watchguard/wg-utils';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RetrieveUserNameComponent } from './retrieve-user-name/retrieve-user-name.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SelectRegionComponent } from './select-region/select-region.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { ErrorComponent } from './error/error.component';
import { environment } from '../environments/environment';
import { HttpMockRequestInterceptor } from './mock-interceptor.service';
import { AuthInterceptor } from './auth-interceptor.service';
import { InviteUserComponent } from './inviteFromSalesforce/invite-user/invite-user.component';
import { InviteUserInNewAccountComponent } from './inviteFromSalesforce/invite-user-in-new-account/invite-user-in-new-account.component';
import { CommonHelperService } from './shared/common-helper.service';
import { EmailComponent } from './email/email.component';

export const isMock = environment.mock;

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpBackend: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(httpBackend, [
    { prefix: 'locale-bundle/wgid-acc-mgr/', suffix: '.json' }
  ]);
}

export function translationInitializerFactory(translate: TranslateService, commonHelperService: CommonHelperService, localeService: LocaleResolverService): () => Promise<void> {
  commonHelperService.loadConfig();
  return () => {
    translate.setDefaultLang('en');
    translate.use(localeService.userPrefLang);
    return new Promise<void>((resolve: (value: void | PromiseLike<void>) => void) => {
      translate.onLangChange.subscribe(() => {
        resolve();
      });
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CreateAccountComponent,
    ResetPasswordComponent,
    RetrieveUserNameComponent,
    SelectRegionComponent,
    SetPasswordComponent,
    ErrorComponent,
    InviteUserComponent,
    InviteUserInNewAccountComponent,
    EmailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HeaderModule,
    WgHideShowPasswordModule,
    WgFormsModule,
    WgButtonModule,
    WgDropdownModule,
    WgNotificationsModule,
    WgDialogModule,
    LoaderModule,
    FormsModule,
    MsalModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: translationInitializerFactory,
      deps: [TranslateService, CommonHelperService, LocaleResolverService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: isMock ? HttpMockRequestInterceptor : AuthInterceptor,
      multi: true
    },
    LoaderService,
    WgNotificationsService,
    CommonHelperService,
    LocaleResolverService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [CommonHelperService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    }
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function MSALInstanceFactory(configService: CommonHelperService): IPublicClientApplication {
  const authConfig = configService.config.auth;
  return new PublicClientApplication({
    auth: {
      clientId: authConfig.clientId,
      authority: authConfig.authority,
      redirectUri: '/change-email',
      postLogoutRedirectUri: authConfig.postLogoutUrl,
      knownAuthorities: authConfig.knownAuthorities
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect
  };
}
