import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { AbstractControl, ValidatorFn, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { NotificationType } from '@watchguard/wg-notifications';
import { LoaderService } from '@watchguard/wg-loader';

import { ADD_NEW_ACC_STRINGS, APP_TITLE_STRINGS, ERROR_COMP_STRINGS, ERROR_STRINGS, RESETS_PASSWORD_STRINGS } from '../shared/ui-strings';
import { CustomValidatorService } from '../shared/custom-validator.service';
import { AccountManagerService } from '../account-manager.service';
import { ResetPassword, ApiResponse, ValidateTokenResponse, UrlData, ValidatePasswordResponse, ErrorContent, Error } from '../shared/model';
import { CommonHelperService } from '../shared/common-helper.service';


@Component({
  selector: 'wgc-set-password',
  templateUrl: './set-password.component.html'
})
export class SetPasswordComponent implements OnInit, OnDestroy{

  isShowSelectRegion: boolean = false;
  isShowSetPasswordForm: boolean = false;
  isShowConfirmation: boolean = false;
  isNewUser: boolean = false;
  isActivateUser: boolean = false;
  isShowOptedInFromEmail: boolean = false;
  isTier1: boolean = true;
  token: string = '';
  userName: string = '';
  password: string = '';
  signInUrl: string = '';
  form: UntypedFormGroup;

  addAccStrs = ADD_NEW_ACC_STRINGS;
  errorStrs = ERROR_STRINGS;
  resetPwdStrs = RESETS_PASSWORD_STRINGS;
  titleStrs = APP_TITLE_STRINGS;
  errorCompStrs = ERROR_COMP_STRINGS;

  setPasswordErrorContent: ErrorContent = {
    errorHeader: this.errorCompStrs.set_password_error_heading,
    errorMessage: this.errorCompStrs.contact_customer_care,
    redirectMessage: this.errorCompStrs.redirect_to_start_reg
  };

  resetPasswordErrorContent: ErrorContent = {
    errorHeader: this.errorCompStrs.reset_password_error_heading,
    errorMessage: this.errorCompStrs.reset_password_error_subheading,
    redirectMessage: this.errorCompStrs.redirect_to_reset_password
  };

  validTokenSub: Subscription;
  resetPasswordSub: Subscription;
  validatePasswordSub: Subscription;

  matchPasswordValFn: ValidatorFn = this.customValidator.commonValFn('password_mismatch');
  passwordCompromisedValFn: ValidatorFn = this.customValidator.commonValFn('password_compromised');
  passwordNotAllowedValFn: ValidatorFn = this.customValidator.commonValFn('password_not_allowed');

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private customValidator: CustomValidatorService,
    private accountManagerService: AccountManagerService,
    private loaderService: LoaderService,
    private commonHelperService: CommonHelperService,
    private translateService: TranslateService,
    private el: ElementRef) {
  }

  ngOnInit(): void {
    this.validateUser();
    this.signInUrl = this.commonHelperService.getUrlByEnv('signIn');
  }

  initForm(): void {
    this.form = this.fb.group({
      password: [
        '',
        [
          this.customValidator.requiredFn('password'),
          this.customValidator.regexFn('password')
        ]
      ],
      confirm_password: [
        '',
        [
          this.customValidator.requiredFn('confirm_password')
        ]
      ],
      optedInFromEmail: [false]
    });
  }

  fetchUrlData(): UrlData {
    const urlData: UrlData = { isValid: false, params: {} };

    this.route.queryParams.subscribe((queryParams: Params) => {
      urlData.params = queryParams;
    });

    const params = urlData.params;

    if (params.new_user && params.token) {
      urlData.isValid = true;
    }

    return urlData;
  }

  validateUser(): void {
    const urlData: UrlData = this.fetchUrlData();

    if (!urlData.isValid) {
      this.commonHelperService.redirectToErrorPage();
      this.commonHelperService.showNotification(
        NotificationType.Error,
        this.errorStrs.api_bad_request_err_msg
      );
    } else {
      this.loaderService.showLoading();

      const queryParams: Params = urlData.params;
      this.token = queryParams.token;
      this.isNewUser = this.commonHelperService.convertQueryParamsToLowercase(queryParams.new_user) === 'true';
      this.isActivateUser = this.commonHelperService.convertQueryParamsToLowercase(queryParams.activate) === 'true';
      this.isShowOptedInFromEmail = this.commonHelperService.convertQueryParamsToLowercase(queryParams.showOptInEmailOption) === 'true';
      this.isTier1 = queryParams.isTier1 ? this.commonHelperService.convertQueryParamsToLowercase(queryParams.isTier1) !== 'false' : true;
      this.validateToken();
    }
  }

  handleValidateTokenResponse(data: ValidateTokenResponse): void {
    if (data.status === 'success' && data.is_token_valid) {
      const appTitle = this.isNewUser ? this.titleStrs.set_password : this.titleStrs.reset_password;
      this.commonHelperService.setTitle(appTitle);

      this.userName = data.user_name;
      this.initForm();
      this.isShowSetPasswordForm = true;
    } else {
      const errorContent = this.getErrorContent(this.isNewUser);
      this.commonHelperService.showApiError(errorContent);
    }

    this.loaderService.hideLoading();
  }

  getErrorContent(isNewUser: boolean): ErrorContent {
    return isNewUser ? this.setPasswordErrorContent : this.resetPasswordErrorContent;
  }

  validateToken(): void {
    const token = this.token || '';

    this.validTokenSub = this.accountManagerService.validateToken(token, this.isNewUser)
    .subscribe({
      next: (data: ValidateTokenResponse) => this.handleValidateTokenResponse(data),
      error: () => this.commonHelperService.handleApiError()
    });
  }

  validateSamePassword(): void {
    const password = this.form.value.password || '';
    const confirmPasswordField: AbstractControl = this.form.controls.confirm_password;
    const passwordField: AbstractControl = this.form.controls.password;

    //Removing the compromised validator only if it's attached to the password field
    if (passwordField.hasValidator(this.passwordCompromisedValFn)) {
      passwordField.removeValidators([this.passwordCompromisedValFn]);
      passwordField.updateValueAndValidity();
    }

    // Removing the used password validator only if it's attached to the password field
    if (passwordField.hasValidator(this.passwordNotAllowedValFn)) {
      passwordField.removeValidators([this.passwordNotAllowedValFn]);
      passwordField.updateValueAndValidity();
    }

    if (password === '' || confirmPasswordField.value === '') {
      confirmPasswordField.removeValidators([this.matchPasswordValFn]);
    } else if (password !== '' && confirmPasswordField.value !== '' && password !== confirmPasswordField.value) {
      confirmPasswordField.addValidators([this.matchPasswordValFn]);
    } else {
      confirmPasswordField.removeValidators([this.matchPasswordValFn]);
    }
    confirmPasswordField.updateValueAndValidity();
  }

  handleResetPasswordResponse(data: ApiResponse): void {
    const passwordField: AbstractControl = this.form.controls.password;
    if (data.status === 'success') {
      if (!this.isTier1) {
        this.signInUrl = this.commonHelperService.getUrlByEnv('signInToCloud');
      }
      this.isShowSetPasswordForm = false;
      this.isShowConfirmation = true;
    } else {
      if (data?.status === 'failed' && data.errors.length > 0) {
        const error: Error = data?.errors[0];
        switch (error.code) {
          case 3304:
            passwordField.addValidators([this.passwordNotAllowedValFn]);
            break;
          default:
            this.commonHelperService.showApiError();
        }
        passwordField.updateValueAndValidity();
      }
      else {
        this.commonHelperService.showApiError();
      }
    }
    this.loaderService.hideLoading();
  }

  handleValidatePasswordResponse(data: ValidatePasswordResponse, passwordField: AbstractControl): void {
    if (data.status === 'success') {
      if (!this.isTier1) {
        this.signInUrl = this.commonHelperService.getUrlByEnv('signInToCloud');
      }
      if (data.password_compromised) {
        passwordField.addValidators([this.passwordCompromisedValFn]);
      } else {
        passwordField.removeValidators([this.passwordCompromisedValFn]);
        this.password = this.form.value.password;
        this.isShowSelectRegion = true;
        this.isShowSetPasswordForm = false;
      }
      passwordField.updateValueAndValidity();
    } else {
      this.commonHelperService.showApiError();
    }
    this.loaderService.hideLoading();
  }

  resetPassword(): void {
    const password = this.form.value.password || '';

    const payload: ResetPassword = {
      username: this.userName,
      token: this.token,
      password,
      activateUser: this.isActivateUser,
      optedInFromEmail: !this.form.value.optedInFromEmail
    };

    this.resetPasswordSub = this.accountManagerService.resetPassword(payload)
    .subscribe({
      next: (data: ApiResponse) => this.handleResetPasswordResponse(data),
      error: () => this.commonHelperService.handleApiError()
    });
  }

  setUserFlow(): void {
    if (this.isNewUser) {
      this.validatePassword();
    } else {
      this.loaderService.showLoading();
      this.resetPassword();
    }
  }

  validatePassword(): void {
    const passwordField: AbstractControl = this.form.controls.password;

    this.loaderService.showLoading();

    this.validatePasswordSub = this.accountManagerService.validatePassword(passwordField.value)
    .subscribe({
      next: (data: ValidatePasswordResponse) => this.handleValidatePasswordResponse(data, passwordField),
      error: () => this.commonHelperService.handleApiError()
    });
  }

  submit(): void {
    if (this.form.valid) {
      this.setUserFlow();
    } else {
      this.form.markAllAsTouched();
      this.commonHelperService.setFocusOnInvalidFormField(this.form, this.el);
    }
  }

  ngOnDestroy(): void {
    this.validTokenSub?.unsubscribe();
    this.resetPasswordSub?.unsubscribe();
    this.validatePasswordSub?.unsubscribe();
  }

}
